import { yupResolver } from '@hookform/resolvers/yup'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, ListItem, ListItemIcon, ListItemText, MenuItem, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded'
import CakeRoundedIcon from '@mui/icons-material/CakeRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded'
import SaveIcon from '@mui/icons-material/Save'
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded'
import WorkRoundedIcon from '@mui/icons-material/WorkRounded'
import { LoadingButton } from '@mui/lab'
import { Divider, List } from '@mui/material'
import { useAlertMessage } from '../../context/notifications'


const FamilySchema = yup.object({
  idPaciente: yup
    .number()
    .required('Campo requerido')
    .typeError('Debe ser un número'),
  tipoVinculo: yup
    .string()
    .required('Campo requerido'),
  nombres: yup
    .string()
    .required('Campo requerido'),
  apPaterno: yup
    .string()
    .required('Campo requerido'),
  apMaterno: yup
    .string()
    .required('Campo requerido'),
  dniApoderado: yup
    .string()
    .length(8, 'El DNI debe tener 8 dígitos')
    .required('Campo requerido'),
  fechaNacimiento: yup
    .date()
    .required('Campo requerido')
    .typeError('Formato de fecha inválido'),
  direccion: yup
    .string()
    .required('Campo requerido'),
  celular: yup
    .string()
    .matches(/^[0-9]{9}$/, 'El número de celular debe tener 9 dígitos')
    .required('Campo requerido'),
  viveConPaciente: yup
    .boolean()
    .required('Campo requerido'),
  gradoInstruccion: yup
    .string()
    .required('Campo requerido'),
  profesion: yup
    .string()
    .required('Campo requerido'),
  centroTrabajo: yup
    .string()
    .required('Campo requerido'),
  cargo: yup
    .string()
    .required('Campo requerido'),
  estadoCivil: yup
    .string()
    .required('Campo requerido'),
  religion: yup
    .string()
    .required('Campo requerido')
})

const formatFamilyMemberData = (disabled) => ({
  // idPaciente: {
  //   label: 'ID Paciente',
  //   value: '--',
  //   adornment: null,
  //   Icon: BadgeRoundedIcon,
  //   color: '#4caf50',
  //   disabled,
  // type: 'string',
  // },
  tipoVinculo: {
    label: 'Tipo de Vínculo',
    value: '--',
    adornment: null,
    Icon: LinkRoundedIcon,
    color: '#3f51b5',
    disabled,
    type: 'select',
    options: [
      {
        "value": "padre",
        "label": "Padre"
      },
      {
        "value": "madre",
        "label": "Madre"
      },
      {
        "value": "hermano",
        "label": "Hermano/a"
      },
      {
        "value": "abuelo",
        "label": "Abuelo/a"
      },
      {
        "value": "tio",
        "label": "Tío/a"
      },
      {
        "value": "primo",
        "label": "Primo/a"
      },
      {
        "value": "esposo",
        "label": "Esposo/a"
      },
      {
        "value": "hijo",
        "label": "Hijo/a"
      },
      {
        "value": "nieto",
        "label": "Nieto/a"
      },
      {
        "value": "sobrino",
        "label": "Sobrino/a"
      },
      {
        "value": "padrastro",
        "label": "Padrastro/Madrastra"
      },
      {
        "value": "yerno",
        "label": "Yerno"
      },
      {
        "value": "nuera",
        "label": "Nuera"
      },
      {
        "value": "cuñado",
        "label": "Cuñado/a"
      },
      {
        "value": "tutor",
        "label": "Tutor"
      },
      {
        "value": "otro",
        "label": "Otro"
      }
    ]
  },
  nombres: {
    label: 'Nombres',
    value: '--',
    adornment: null,
    Icon: PersonRoundedIcon,
    color: '#ff9800',
    disabled,
    type: 'string',
  },
  apPaterno: {
    label: 'Apellido Paterno',
    value: '--',
    adornment: null,
    Icon: PersonRoundedIcon,
    color: '#9c27b0',
    disabled,
    type: 'string',
  },
  apMaterno: {
    label: 'Apellido Materno',
    value: '--',
    adornment: null,
    Icon: PersonRoundedIcon,
    color: '#f44336',
    disabled,
    type: 'string',
  },
  dniApoderado: {
    label: 'DNI del Apoderado',
    value: '--',
    adornment: null,
    Icon: ContactMailRoundedIcon,
    color: '#2196f3',
    disabled,
    type: 'number',
  },
  fechaNacimiento: {
    label: 'Fecha de Nacimiento',
    value: '--',
    adornment: null,
    Icon: CakeRoundedIcon,
    color: '#795548',
    disabled,
    type: 'date',
  },
  direccion: {
    label: 'Dirección',
    value: '--',
    adornment: null,
    Icon: HomeRoundedIcon,
    color: '#607d8b',
    disabled,
    type: 'string',
  },
  celular: {
    label: 'Celular',
    value: '--',
    adornment: null,
    Icon: PhoneRoundedIcon,
    color: '#ff5722',
    disabled,
    type: 'number',
  },
  viveConPaciente: {
    label: 'Vive con Paciente',
    value: '--',
    adornment: null,
    Icon: PeopleAltRoundedIcon,
    color: '#4caf50',
    disabled,
    type: 'select',
    options: [{
      value: true,
      label: 'Si'
    }, {
      value: false,
      label: 'No'
    }]
  },
  gradoInstruccion: {
    label: 'Grado de Instrucción',
    value: '--',
    adornment: null,
    Icon: SchoolRoundedIcon,
    color: '#3f51b5',
    disabled,
    type: 'select',
    options: [
      {
        "value": "ninguno",
        "label": "Ninguno"
      },
      {
        "value": "primaria",
        "label": "Primaria"
      },
      {
        "value": "secundaria",
        "label": "Secundaria"
      },
      {
        "value": "superior",
        "label": "Superior"
      },
      {
        "value": "tecnico",
        "label": "Técnico"
      },
      {
        "value": "universitario",
        "label": "Universitario/a"
      },
      {
        "value": "postgrado",
        "label": "Postgrado"
      },
      {
        "value": "otro",
        "label": "Otro"
      }
    ]
  },
  profesion: {
    label: 'Profesión',
    value: '--',
    adornment: null,
    Icon: WorkRoundedIcon,
    color: '#9c27b0',
    disabled,
    type: 'string',
  },
  centroTrabajo: {
    label: 'Centro de Trabajo',
    value: '--',
    adornment: null,
    Icon: BusinessRoundedIcon,
    color: '#607d8b',
    disabled,
    type: 'string',
  },
  cargo: {
    label: 'Cargo',
    value: '--',
    adornment: null,
    Icon: WorkRoundedIcon, // Se puede repetir este ícono
    color: '#f44336',
    disabled,
    type: 'string',
  },
  estadoCivil: {
    label: 'Estado Civil',
    value: '--',
    adornment: null,
    Icon: FavoriteRoundedIcon,
    color: '#ff9800',
    disabled,
    type: 'select',
    options: [
      {
        "value": "soltero",
        "label": "Soltero/a"
      },
      {
        "value": "casado",
        "label": "Casado/a"
      },
      {
        "value": "divorciado",
        "label": "Divorciado/a"
      },
      {
        "value": "viudo",
        "label": "Viudo/a"
      },
      {
        "value": "unido",
        "label": "Unido/a"
      },
      {
        "value": "en_pareja",
        "label": "En Pareja"
      },
      {
        "value": "otro",
        "label": "Otro"
      }
    ]
  },
  religion: {
    label: 'Religión',
    value: '--',
    adornment: null,
    Icon: PublicRoundedIcon,
    color: '#795548',
    disabled,
    type: 'select',
    options: [
      {
        "value": "catolica",
        "label": "Católica"
      },
      {
        "value": "evangelica",
        "label": "Evangélica"
      },
      {
        "value": "musulmana",
        "label": "Musulmana"
      },
      {
        "value": "judia",
        "label": "Judía"
      },
      {
        "value": "mormona",
        "label": "Mormona"
      },
      {
        "value": "hinduista",
        "label": "Hinduista"
      },
      {
        "value": "budista",
        "label": "Budista"
      },
      {
        "value": "sinReligion",
        "label": "Sin religión"
      },
      {
        "value": "otro",
        "label": "Otro"
      }
    ]
  },
});

export const FamilyMembersDialog = ({ defaultData, handleClose, open, estadoTriaje, mutate, isLoading }) => {
  const familyMemberDefaultData = formatFamilyMemberData(estadoTriaje)
  const { showError, showSuccess } = useAlertMessage()

  const { reset, control, formState: { errors }, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(FamilySchema),
    defaultValues: {
      idPaciente: defaultData?.idPaciente ?? "",
      tipoVinculo: defaultData?.tipoVinculo ?? "",
      nombres: defaultData?.nombres ?? "",
      apPaterno: defaultData?.apPaterno ?? "",
      apMaterno: defaultData?.apMaterno ?? "",
      dniApoderado: defaultData?.dniApoderado ?? "",
      fechaNacimiento: defaultData?.fechaNacimiento ?? "",
      direccion: defaultData?.direccion ?? "",
      celular: defaultData?.celular ?? "",
      viveConPaciente: defaultData?.viveConPaciente ?? "",
      gradoInstruccion: defaultData?.gradoInstruccion ?? "",
      profesion: defaultData?.profesion ?? "",
      centroTrabajo: defaultData?.centroTrabajo ?? "",
      cargo: defaultData?.cargo ?? "",
      estadoCivil: defaultData?.estadoCivil ?? "",
      religion: defaultData?.religion ?? "",
    }
  })

  const onSubmit = (data) => {
    const msg = 'No se pudo guardar datos del familiar'
    const msg2 = 'Datos guardados correctamente'
    mutate({
      ...data,
      fechaNacimiento: new Date(data.fechaNacimiento).toJSON()
    }, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
        reset()
        handleClose()
      }
    })
  }

  const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
      >
        Agregar familiar
        <CloseRoundedIcon
          onClick={() => {
            handleClose()
            reset()
          }}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={3} alignContent='center'>
            <Grid item xs={12}>
              <Grid container justifyContent="space-around" >
                <Grid item xs={12} sm={12} p={2}>
                  <List>
                    {
                      Object.keys(familyMemberDefaultData).map((key, index) => {
                        const { label, Icon, adornment, color, type, options } = familyMemberDefaultData[key]
                        return (
                          <div key={index}>
                            <Divider />
                            <ListItem className='my-[2px]'>
                              <ListItemIcon> <Icon sx={{ color: color }} /> </ListItemIcon>
                              <ListItemText>
                                <Typography fontWeight="bold">
                                  {label}
                                </Typography>
                              </ListItemText>
                              {
                                type !== 'select' ? <Controller
                                  control={control}
                                  name={key}
                                  render={({ field: { onChange, value } }) => (
                                    <TextField
                                      onChange={onChange}
                                      type={type}
                                      value={value}
                                      sx={{ width: 200 }}
                                      size="small"
                                      // disabled={disabled}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
                                      }}
                                      helperText={errors[key]?.message}
                                      error={!!errors[key]}
                                    />
                                  )}
                                /> :
                                  <Controller
                                    control={control}
                                    name={key}
                                    render={({ field: { onChange, value } }) => (
                                      <TextField
                                        onChange={onChange}
                                        select
                                        value={value}
                                        sx={{ width: 200 }}
                                        size="small"
                                        // disabled={disabled}
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">{adornment}</InputAdornment>,
                                        }}
                                        helperText={errors[key]?.message}
                                        error={!!errors[key]}
                                      >
                                        {
                                          options.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)
                                        }
                                      </TextField>
                                    )}
                                  />
                              }
                            </ListItem>
                          </div>
                        )
                      })
                    }
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => handleClose()}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            type='submit'
            loadingPosition='start'
            startIcon={<SaveIcon />}
            variant='contained'
          >
            GUARDAR
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}