import { Grid, MenuItem, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState, useTransition } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useGetAllEspecialista } from '../../../hooks/useEspecialistas'
import { useGetHistorialCitasEspecialista } from '../../../hooks/back-clinica/useCitas'
import { Circle } from '@mui/icons-material'
import { semaforoColors } from '../../../components/Semaforo/colors'

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])

  return debouncedValue
}

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Fecha atención',
    field: 'fechaAtencion',
    flex: 1,
    valueFormatter: ({ value }) => {
      return value?.split('T')[0] ?? '--'
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Paciente',
    field: 'paciente',
    flex: 2,
    cellClassName: 'uppercase',
    // width: 120,
    // valueFormatter: ({ value }) => { return value.toUpperCase()
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Especialidad',
    field: 'especialidad',
    flex:1,
    // width: 120,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Especialista',
    field: 'especialista',
    cellClassName: 'uppercase',
    flex:2,
    // width: 300,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Diagnóstico',
    field: 'diagnostico',
    cellClassName: 'uppercase',
    flex: 3,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Semáforo',
    field: 'semaforo',
    cellClassName: 'uppercase',
    flex: 1.5,
    // width: 150,
    // type: 'string'
    renderCell: params => {
      const semaforo = params.row.semaforo
      const notFound = { text: 'No especificado', color: "#949494" }
      const {text, color} = semaforoColors.find(item => item.value === semaforo) || notFound
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Circle sx={{ color }} />
          <span>{text}</span>
        </div>
      )
    }
  }
]

const now = new Date().toISOString().split('T')[0]

export const HistorialCitasEspecialista = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [_isPending, startTransition] = useTransition()
  const idEspecialista = searchParams.get('idEspecialista') || null
  const fechaInicial = searchParams.get('fechaInicial') || now
  const fechaFinal = searchParams.get('fechaFinal') || now

  const { data: especialistas } = useGetAllEspecialista()
  const { data: historialData, isLoading } =
    useGetHistorialCitasEspecialista(
      idEspecialista,
      fechaInicial,
      fechaFinal
    ) || []

  const [dateRange, setDateRange] = useState([fechaInicial, fechaFinal])

  const debouncedDateRange = useDebounce(dateRange, 500)

  const createQueryString = useCallback(
    params => {
      const newSearchParams = new URLSearchParams(searchParams.toString())

      for (const [key, value] of Object.entries(params)) {
        if (value === null || value === undefined || value === '') {
          newSearchParams.delete(key)
        } else {
          newSearchParams.set(key, value)
        }
      }
      return newSearchParams.toString()
    },
    [searchParams]
  )

  useEffect(() => {
    const [fechaInicial, fechaFinal] = debouncedDateRange
    startTransition(() => {
      const newQueryString = createQueryString({
        fechaInicial,
        fechaFinal
      })
      navigate(`${pathname}?${newQueryString}`, {
        scroll: false
      })
    })
  }, [debouncedDateRange])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid
            container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>Citas</Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label='Especialidades'
                size='small'
                select
                defaultValue={idEspecialista}
                onChange={e => {
                  const newSearchParams = createQueryString({
                    idEspecialista: e.target.value
                  })
                  navigate(`${pathname}?${newSearchParams.toString()}`, {
                    scroll: false
                  })
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              >
                <MenuItem value=''>TODOS</MenuItem>
                {especialistas?.map(especialista => (
                  <MenuItem
                    key={especialista.idEspecialista}
                    value={especialista.idEspecialista}
                  >
                    {especialista.persona.nombres +
                      ' ' +
                      especialista.persona.apPaterno +
                      ' ' +
                      especialista.persona.apMaterno}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label='Fecha Inicial'
                type='date'
                size='small'
                defaultValue={fechaInicial}
                onChange={e => setDateRange(prev => [e.target.value, prev[1]])}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label='Fecha Final'
                type='date'
                size='small'
                defaultValue={fechaInicial}
                onChange={e => setDateRange(prev => [prev[0], e.target.value])}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4}>
              <span style={{ fontWeight: 'bold' }}>Paciente:</span>
              <span> {nombreCompletoPaciente} </span>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography style={{ fontWeight: 'bold' }}>Resumen </Typography>
              <span style={{ fontWeight: 'bold' }}>Total de atenciones: </span>
              <span>{historialData?.total}</span>
            </Grid>
            <Grid item xs={12}>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={500}
                  rows={historialData?.data || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={row => row.idCita}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
