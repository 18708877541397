import { useMutation, useQuery, useQueryClient } from 'react-query'
import { addFamilyMembers, getFamilyMembers } from '../../services/back-clinica/pacientes'

const key = 'pacientes'

export const useGetFamilyMembers = (idPaciente, enabled = true) =>
  useQuery({
    queryFn: () => getFamilyMembers(idPaciente),
    select: (data) => data.map(item => ({ ...item, nombreApoderado: `${item?.nombres} ${item?.apPaterno} ${item?.apMaterno}` })),
    queryKey: [key],
    enabled,
  })

export const useAddFamilyMember = () => {
  const queryClient = useQueryClient()
  return useMutation(
    body => addFamilyMembers(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}