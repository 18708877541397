import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import { Button, IconButton, Tooltip } from '@mui/material'
import React, { useContext, useState } from 'react'
import { UserContext } from '../../../../context/userContext'
import { useCreateTriaje } from '../../../../hooks/back-clinica/useTriajeClinica'
import { DialogTriaje } from './DialogTriaje'

export default function Triaje({ data }) {
    const { mutate, isLoading } = useCreateTriaje()
    const { especialidad } = useContext(UserContext)

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    if (!especialidad?.requiereTriaje) {
        return <Button disabled>
            Triaje
        </Button>
    }

    const haveTriaje = !!data.triaje

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Tooltip title={haveTriaje ? "Ver triaje" : "No tiene triaje"}>
                    <MedicalInformationIcon color={haveTriaje ? "primary" : "secondary"} />
                </Tooltip>
            </IconButton>
            {
                open &&
                <DialogTriaje
                    mutate={mutate}
                    defaultData={{
                        ...data.triaje,
                        idCita: data.id,
                        nroDocPaciente: data.dni,
                        estadoTriaje: true,
                    }}
                    isLoading={isLoading}
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                />
            }
        </>
    )
}
