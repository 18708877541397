import { yupResolver } from '@hookform/resolvers/yup'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@mui/material'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { DataGridStyle } from '../../../../components/DataGridStyle'
import { DatosGenerales } from '../../../../components/DatosGenerales'
import AddFamilyMember from '../../../../components/FamilyMembers/AddFamilyMember'
import Loading from '../../../../components/Loading'
import ContentMainBox from '../../../../components/MainBox'
import { Semaforo } from '../../../../components/Semaforo'
import { EspecialidadContext } from '../../../../context/especialidadContext'
import { useAlertMessage } from '../../../../context/notifications'
import { useGetFamilyMembers } from '../../../../hooks/back-clinica/usePacientes'
import {
  useAtenderPaciente,
  useGetDatosCita
} from '../../../../hooks/useAtencionPaciente'

const CamposPaciente = [
  'nombres',
  'apellidos',
  'fechaNacimiento',
  'direccion',
  'celular',
  'alergias',
  'personaResponsable',
  'edad',
  'procedencia',
  'carreraProfesional',
  'numeroHermanos',
  'accidentesGraves',
  'codigoEstudiante',
  'semestre',
  'email',
  'tutor',
  'viveResidenciaUniv',
  'idioma',
  'religion',
  'LugarOcupaHermano'
]

export const FormFichaPersonalEstudiante = () => {
  const { showError, showSuccess } = useAlertMessage()
  const { state: dataCita } = useLocation()
  const { data: dataCitaCampos, isLoading } = useGetDatosCita({
    idFormato: dataCita.idFormato,
    idCita: dataCita.id
  })
  const { data: familyMembers } = useGetFamilyMembers(dataCita.idPaciente)

  const { mutate, isLoading: isLoadingAtender } = useAtenderPaciente()

  const { especialidad } = useContext(EspecialidadContext)
  const navigate = useNavigate()
  const [showForm1, setShowForm1] = useState(true)

  const { control, formState: { errors }, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(yup.object({
      semaforo: yup
        .number()
        .required('Campo requerido')
        .typeError('Campo requerido'),
    })),
  })
  const onSubmit = data => {

    const DatosEnviar = {
      idCita: dataCitaCampos.idCita,
      semaforo: data.semaforo,
      consultaDatos: null
    }

    const msg = 'No se pudo guardar datos de la atencion o ya fue creada'
    const msg2 = 'Datos guardados correctamente o ya fue creada'
    mutate(DatosEnviar, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        showSuccess(msg2)
        navigate(`/especialista/nuevasconsultas`)
      }
    })
  }
  return (
    <ContentMainBox>
      <ContentMainBox.Title>
        <Box className='flex items-center'>
          <IconButton onClick={() => setShowForm1(!showForm1)} size='small'>
            {showForm1 ? (
              <VisibilityRoundedIcon />
            ) : (
              <VisibilityOffRoundedIcon />
            )}
          </IconButton>
          <Typography className='font-bold'>
            Ficha Personal del Estudiante
          </Typography>
        </Box>
      </ContentMainBox.Title>
      <Collapse in={showForm1}>
        <ContentMainBox.Content>
          <Loading isLoading={isLoading}>
            {dataCitaCampos && (
              <Grid container spacing={2} paddingX={10}>
                <Grid item xs={12}>
                  <DatosGenerales
                    DNI={dataCita.dni}
                    triaje={especialidad.requiereTriaje}
                    CamposPaciente={CamposPaciente}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='space-between' mb={2}>
                    <Typography variant='subtitle1' fontWeight='bold'>
                      Familiares
                    </Typography>
                    <AddFamilyMember data={{ idPaciente: dataCita.idPaciente }} />
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <DataGridStyle
                    height={380}
                    rows={familyMembers ?? []}
                    columns={columns}
                    autoHeight={true}
                    getRowId={(row) => row?.idFamiliar}
                  />
                </Grid>
                <Grid xs={12}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid item container spacing={2}>
                      <Grid item xs={4}>
                        <Semaforo
                          control={control}
                          id='semaforo'
                          label='Semaforo'
                        />
                        <Typography variant='subtitle2' align='left' color='red'>
                          {errors.semaforo?.message}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid item container justifyContent='space-evenly'>
                          <Grid item xs={5}>
                            <Button fullWidth variant='outlined'
                              onClick={() =>
                                navigate(-1)
                              }
                            >
                              Cancelar
                            </Button>
                          </Grid>
                          <Grid item xs={5}>
                            <LoadingButton
                              loading={isLoadingAtender}
                              type='submit'
                              fullWidth
                              variant='contained'
                            >
                              Guardar
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            )}
          </Loading>
        </ContentMainBox.Content>
      </Collapse>
    </ContentMainBox >
  )
}


const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'nroDoc',
    minWidth: 150,
    flex: 0.1,
    headerName: 'DNI',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'nombreApoderado',
    minWidth: 250,
    flex: 0.5,
    headerName: 'Nombre',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'celular',
    headerName: 'Telefono',
    minWidth: 150,
    flex: 0.1,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'tipoVinculo',
    headerName: 'Familiar',
    minWidth: 150,
    flex: 0.1,
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    headerName: 'Acciones',
    minWidth: 150,
    flex: 0.1,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    // renderCell: ({ value }) => {
    //   return <Chip size="small" color={COLOR_ESTADO[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={ESTADOS[value]}
    //   />
    // }
  },
]