import { axiosClinicaClient } from "../../api/axios"

export const getFamilyMembers = async (idPaciente) => {
  const { data } = await axiosClinicaClient.get(`/mantenimiento/pacientes/familiares/obtener/${idPaciente}`)
  return data
}

export const addFamilyMembers = async (body) => {
  const { data } = await axiosClinicaClient.post(`/mantenimiento/pacientes/familiares/crear`, body)
  return data
}
