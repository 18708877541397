import { Chip, Grid, MenuItem, TextField } from '@mui/material'
import React, { useCallback, useContext, useEffect, useState, useTransition } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { COLOR_ESTADO, ESTADOS } from '../../../constants'
import { UserContext } from '../../../context/userContext'
import { useGetTriajes } from '../../../hooks/back-clinica/useTriajeClinica'
import { useDebounce } from '../../../hooks/utils/useDebounce'
import Triaje from './components/Triaje'

const columns = [
  // {
  //   headerClassName: 'super-app-theme--header',
  //   field: 'id',
  //   headerName: 'ID',
  //   type: 'string',
  //   headerAlign: 'center',
  //   align: 'center',
  // },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fechaCita',
    headerName: 'Fecha',
    type: 'date',
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => {
      return value.split('T')[0]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora',
    type: 'string',
    headerAlign: 'center',
    align: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'especialidad',
    headerName: 'Especialidad',
    width: 220,
    type: 'string',
    headerAlign: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'paciente',
    headerName: 'Paciente',
    minWidth: 300,
    flex: 1,
    type: 'string',
    headerAlign: 'center',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estadoTriaje',
    headerName: 'Estado',
    width: 150,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell({ value }) {
      return (
        <Chip size="small" color={COLOR_ESTADO[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={ESTADOS[value]} />
      )
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'action',
    headerName: 'Atender',
    width: 220,
    type: 'string',
    headerAlign: 'center',
    align: 'center',
    renderCell: ({ row }) => {
      return (
        <div className='space-x-2'>
          <Triaje data={row} />
        </div>

      )
    }
  }
]

export const AreaTriaje = () => {
  const { user, especialidad: espe } = useContext(UserContext)
  const estado = 1 // (Citas atendidas / Diferentes de pendiente)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const idEspecialidad = searchParams.get('especialidad') || ''
  const fechaInicial = searchParams.get('fechaInicial') || ''
  const fechaFinal = searchParams.get('fechaFinal') || ''
  const dniPaciente = searchParams.get('dniPaciente') || ''
  const estadoTriaje = searchParams.get('estadoTriaje') || 'pendiente'

  const { data, isLoading } = useGetTriajes({
    idEspecialidad,
    fechaInicial,
    fechaFinal,
    dniPaciente,
    estadoTriaje,
  })
  console.log(data)

  const [isPending, startTransition] = useTransition()
  const [dateRange, setDateRange] = useState([
    fechaInicial,
    fechaFinal,
  ])
  const [search, setSearch] = useState('')

  const debouncedDateRange = useDebounce(dateRange, 500)
  const debouncedSearch = useDebounce(search, 500)

  const createQueryString = useCallback((params) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())

    for (const [key, value] of Object.entries(params)) {
      if (value === null || value === undefined || value === '') {
        newSearchParams.delete(key)
      } else {
        newSearchParams.set(key, value)
      }
    }

    return newSearchParams.toString()
  }, [searchParams])


  useEffect(() => {
    const [fechaInicial, fechaFinal] = debouncedDateRange
    startTransition(() => {
      const newQueryString = createQueryString({
        fechaInicial,
        fechaFinal,
        dniPaciente: debouncedSearch,
      })

      navigate(`${pathname}?${newQueryString}`, {
        scroll: false,
      })
    })
  }, [debouncedDateRange, debouncedSearch])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Área de Triaje
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} >
              <TextField
                label="Buscar paciente (DNI)"
                size="small"
                defaultValue={dniPaciente}
                onChange={(e) => setSearch(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2} >
              <TextField
                label="Estado"
                size="small"
                select
                defaultValue={estadoTriaje}
                onChange={(e) => {
                  const newSearchParams = createQueryString({
                    "estadoTriaje": e.target.value,
                  })

                  navigate(`${pathname}?${newSearchParams.toString()}`, {
                    scroll: false,
                  })
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="atendido">Atendido</MenuItem>
                <MenuItem value="pendiente">Pendiente</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2.5} >
              <TextField
                label="Especialidades"
                size="small"
                select
                defaultValue={idEspecialidad}
                onChange={(e) => {
                  const newSearchParams = createQueryString({
                    "especialidad": e.target.value,
                  })

                  navigate(`${pathname}?${newSearchParams.toString()}`, {
                    scroll: false,
                  })
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="">TODOS</MenuItem>
                {
                  especialidades?.map((especialidad) => (
                    <MenuItem key={especialidad.idEspecialidad} value={especialidad.idEspecialidad}>{especialidad.desEspecialidad}</MenuItem>
                  ))
                }
              </TextField>
            </Grid> */}
            <Grid item xs={12} sm={6} md={2} >
              <TextField
                label="Fecha Inicial"
                type="date"
                size="small"
                defaultValue={fechaInicial}
                onChange={(e) => setDateRange((prev) => [e.target.value, prev[1]])}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Fecha Final"
                type="date"
                size="small"
                defaultValue={fechaInicial}
                onChange={(e) => setDateRange((prev) => [prev[0], e.target.value])}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>

              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={380}
                  rows={data || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.idCita}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}