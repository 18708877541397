import { Box, Button, Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetFormatosCita } from '../../../hooks/useAtencionPaciente';
import Loading from '../../../components/Loading';
import { UserContext } from '../../../context/userContext';

export const ModuloMedicinaGeneral = () => {
  const { state } = useLocation();
  const {especialidad}=useContext(UserContext)
  const {data, isLoading}=useGetFormatosCita({idEspecialidad: especialidad?.idEspecialidad})
  const navigate = useNavigate();
  const [showForms, setShowForms] = useState(data|| []);

  const handleButtonClick = (formato, index) => {
    const route = formato.nombreFormato
    .normalize("NFD") 
    .replace(/[\u0300-\u036f]/g, "") 
    .toLowerCase()
    .replace(/\s/g, '-');
    navigate(`/especialista/medicinageneral/${route}`, { state: {...state,...formato} });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Loading isLoading={isLoading}>
        {data &&
        data.map((formato, index) => (
          <Grid item xs={12} key={formato.idFormato}>
            <Button
              fullWidth
              sx={{
                justifyContent: 'start',
                backgroundColor: '#F2F4F4',
                color: 'black',
                borderRadius: '10px',
                padding: '12px',
                paddingLeft: 5,
                border: '1px solid',
                borderColor: '#bdbdbd',
                fontWeight: 500,
                '&:hover': {
                  backgroundColor: '#bdbdbd',
                  borderColor: '#bdbdbd',
                },
              }}
              onClick={() => handleButtonClick(formato, index)}
            >
              {index+1}. {formato.nombreFormato}
            </Button>
          </Grid>
        ))}
        </Loading>
      </Grid>
    </>
  );
};
