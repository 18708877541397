export const semaforoColors = [
  {
    value: 0,
    text: 'Verde',
    color: '#4CAF50'
  },
  {
    value: 1,
    text: 'Naraja',
    color: '#FFC107'
  },
  {
    value: 2,
    text: 'Rojo',
    color: '#F44336'
  }
]
