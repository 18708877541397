export const ESTADOS = {
    0: "Pendiente",
    1: "Atendido",
    2: "Derivado",
    3: "No asistio",
}

export const COLOR_ESTADO = {
    0: "warning",
    1: "success",
    2: "error",
    3: "info",
}
