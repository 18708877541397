import { axiosClinicaClient } from "../../api/axios"

export const createTriaje = async (body) => {
  const { data } = await axiosClinicaClient.post('/procesos/triajes', body)
  return data
}

export const getTriajes = async (searchParams) => {
  const { fechaInicial: fechaInicio, fechaFinal: fechaFin, dniPaciente, idEspecialidad, estadoTriaje } = searchParams
  const queryParams = [
    fechaInicio && `fechaInicio=${fechaInicio}`,
    fechaFin && `fechaFin=${fechaFin}`,
    dniPaciente && `dniPaciente=${dniPaciente}`,
    idEspecialidad && `idEspecialidad=${idEspecialidad}`,
    estadoTriaje && `estadoTriaje=${estadoTriaje}`,
    // estado && `estadoCitaMedica=${estado}`
  ].filter(Boolean)

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''

  const { data } = await axiosClinicaClient.get(`/procesos/triajes${queryString}`)
  return data
}