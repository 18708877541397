import { Button, Grid, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState, useTransition } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Loading from '../../../components/Loading'
import ContentMainBox from '../../../components/MainBox'
import { useGetHistorialCitasPaciente } from '../../../hooks/back-clinica/useCitas'
import { Circle } from '@mui/icons-material'
import { semaforoColors } from '../../../components/Semaforo/colors'

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  // const [dniPaciente, set] = useState()

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay ?? 500)
    return () => {
      clearTimeout(timer)
    }
  }, [value, delay])
  return debouncedValue
}

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Fecha atención',
    field: 'fechaAtencion',
    // width: 120,
    flex: 1,
    valueFormatter: ({ value }) => {
      return value?.split('T')[0] ?? '--'
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Especialidad',
    field: 'especialidad',
    // width: 120,
    flex: 1,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Especialista',
    field: 'especialista',
    cellClassName: 'uppercase',
    // width: 300,
    flex: 2,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Diagnóstico',
    field: 'diagnostico',
    cellClassName: 'uppercase',
    flex: 2,
    // width: 300,
    type: 'string'
  },
  {
    headerClassName: 'super-app-theme--header',
    headerName: 'Semáforo',
    field: 'semaforo',
    flex: 1,
    cellClassName: 'uppercase',
    width: 150,
    // type: 'string'
    renderCell: params => {
      const semaforo = params.row.semaforo
      const notFound = { text: 'No especificado', color: '#949494' }
      const { text, color } =
        semaforoColors.find(item => item.value === semaforo) || notFound
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Circle sx={{ color }} />
          <span>{text}</span>
        </div>
      )
    }
  }
]

const now = new Date().toISOString().split('T')[0]

export const HistorialCitasPaciente = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [, startTransition] = useTransition()
  // const [dniPaciente, setDniPaciente] = useState(null)
  const dniPaciente = searchParams.get('dniPaciente') || null
  const fechaInicial = searchParams.get('fechaInicial') || now
  const fechaFinal = searchParams.get('fechaFinal') || now

  // const {regi} =useForm()
  const {
    data: historialData,
    isLoading,
    refetch
  } = useGetHistorialCitasPaciente(
    dniPaciente,
    fechaInicial,
    fechaFinal,
    false
  ) || []

  const paciente = historialData?.data?.paciente

  let nombreCompletoPaciente = ''
  if (paciente?.persona != null) {
    nombreCompletoPaciente =
      paciente?.persona?.nombres + ' ' +
      paciente?.persona?.apPaterno + ' ' +
      paciente?.persona?.apMaterno
  }

  // console.log(historialData, !historialData)
  // if (dniPaciente?.length != null && !historialData) {
  // refetch()
  // }

  const [dateRange, setDateRange] = useState([fechaInicial, fechaFinal])

  const debouncedDateRange = useDebounce(dateRange, 500)

  const createQueryString = useCallback(
    params => {
      const newSearchParams = new URLSearchParams(searchParams.toString())

      for (const [key, value] of Object.entries(params)) {
        if (value === null || value === undefined || value === '') {
          newSearchParams.delete(key)
        } else {
          newSearchParams.set(key, value)
        }
      }
      return newSearchParams.toString()
    },
    [searchParams]
  )

  useEffect(() => {
    const [fechaInicial, fechaFinal] = debouncedDateRange
    startTransition(() => {
      const newQueryString = createQueryString({
        fechaInicial,
        fechaFinal
      })
      navigate(`${pathname}?${newQueryString}`, {
        scroll: false
      })
    })
  }, [debouncedDateRange])

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <Grid
            container
            spacing={2}
            justifyContent='space-between'
            alignItems='center'
          >
            <Grid item>Citas</Grid>
          </Grid>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label='Dni del paciente'
                size='small'
                defaultValue={dniPaciente}
                // onChange={e => {
                //   const newSearchParams = createQueryString({
                //     dniPaciente: e.target.value
                //   })
                // }}
                onChange={e => {
                  if (e.target.value.length === 8) {
                    const newSearchParams = createQueryString({
                      dniPaciente: e.target.value
                    })
                    navigate(`${pathname}?${newSearchParams.toString()}`, {
                      scroll: false
                    })
                  }
                }}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label='Fecha Inicial'
                type='date'
                size='small'
                defaultValue={fechaInicial}
                onChange={e => setDateRange(prev => [e.target.value, prev[1]])}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <TextField
                label='Fecha Final'
                type='date'
                size='small'
                defaultValue={fechaInicial}
                onChange={e => setDateRange(prev => [prev[0], e.target.value])}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <Button
                variant='contained'
                onClick={() => {
                  refetch()
                }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <span style={{ fontWeight: 'bold' }}>Paciente:</span>
              <span> {nombreCompletoPaciente} </span>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <span style={{ fontWeight: 'bold' }}>Tipo paciente: </span>
              <span>{paciente?.tipoPaciente}</span>
            </Grid>
            <Grid item xs={12}>
              <Loading isLoading={isLoading}>
                <DataGridStyle
                  height={500}
                  rows={historialData?.data?.historial || []}
                  columns={columns}
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={row => row.idCita}
                />
              </Loading>
            </Grid>
          </Grid>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}
