import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { setClient } from '../api/axios'
import { usePath } from '../hooks/usePath'

export const LoginGoogleService = async (token) => {
    const { full_path: fullPath } = usePath()
    const response = await axios.post(
        `${fullPath}/bienestar/Login/google?GOOGLE_AUTH_TOKEN=${token}`)
    if (response.status !== 200 && response.status !== 201) {
        return { success: false, formErrors: response.data }
    }
    setClient(response.data.listaObjetos[0].token)
    return { success: true, data: response.data }
}

export const LoginService = async dataLogin => {
    const { full_path: fullPath } = usePath()
    const response = await axios.post(
        `${fullPath}/bienestar/Login`,
        dataLogin,
        {
            headers: { Authorization: 'nt' },
        })

    if (response.status !== 200 && response.status !== 201) {
        return { success: false, formErrors: response.data }
    }
    setClient(response.data.listaObjetos[0].token)
    return { success: true, data: response.data }
}

export const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    if (token === "") return false;
    setClient(token)
    if (token) {
        var decodedToken = jwtDecode(token, {
            complete: true
        });
        var dateNow = new Date();
        if (decodedToken.exp < dateNow.getTime() / 1000) {
            localStorage.removeItem("token");
            return false;
        } else {
            return true;
        }
    }
    return false;
}
