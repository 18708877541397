import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import { Button, ButtonGroup, Chip, Grid } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { DataGridStyle } from '../../../components/DataGridStyle'
import Input, { InputDate } from '../../../components/Input'
import ContentMainBox from '../../../components/MainBox'
import { useGetCitasPaciente } from '../../../hooks/back-clinica/useCitas'
import { useGetAllEspecialidad } from '../../../hooks/useEspecialidades'
import Loading from '../../../components/Loading'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetPacienteByNroDoc } from '../../../hooks/usePacientes'
import { COLOR_ESTADO, ESTADOS } from '../../../constants'

const schema = yup.object({
  idEspecialidad: yup.string().required(),
  fechaInicio: yup.string().required(),
  fechaFin: yup.string().required(),
})

export const MisCitas = () => {
  let { data: Especialidades } = useGetAllEspecialidad() || []
  const dniPaciente = JSON.parse(localStorage.getItem("dni"))
  let { data: pacienteData } = useGetPacienteByNroDoc(dniPaciente) || []
  const dataEspecialidades = Especialidades ? Especialidades.map((especialidad) => {
    return {
      value: especialidad.idEspecialidad,
      denominacion: especialidad.desEspecialidad
    }
  }
  ) : []

  const { control, watch, reset, formState, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      idEspecialidad: '',
      fechaInicio: new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' }),
      fechaFin: new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' })
    }
  })
  const { isValid } = formState

  const handleSearch = (data) => {
    refetch()
  }

  const { data, isLoading, refetch } = useGetCitasPaciente({
    idPaciente: pacienteData?.idPaciente,
    fechaInicio: watch('fechaInicio'),
    fechaFin: watch('fechaFin'),
    idEspecialidad: watch('idEspecialidad')
  }, false)

  const clearFilters = () => {
    reset({
      idEspecialidad: '',
      fechaInicio: new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' }),
      fechaFin: new Date().toLocaleDateString('sv', { timeZone: 'America/Lima' })
    })
  }


  const citas = data?.data

  return (
    <>
      <ContentMainBox>
        <ContentMainBox.Title>
          <div className='flex justify-between'>
            Mis Citas
          </div>
        </ContentMainBox.Title>
        <ContentMainBox.Content>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={6}>
                <Input
                  control={control}
                  fullWidth
                  id='idEspecialidad'
                  label='Especialidad'
                  size='small'
                  type='select'
                  data={dataEspecialidades}
                  error={!!formState.errors.idEspecialidad}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <InputDate
                  control={control}
                  fullWidth
                  id='fechaInicio'
                  label='Fecha inicial'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={!!formState.errors.fechaInicio}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <InputDate
                  control={control}
                  fullWidth
                  id='fechaFin'
                  label='Fecha final'
                  size='small'
                  type='date'
                  InputLabelProps={{ shrink: true }}
                  error={!!formState.errors.fechaFin}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <ButtonGroup fullWidth variant="outlined" aria-label="outlined button group">
                  <Button
                    sx={{ width: '80%' }}
                    variant='contained'
                    startIcon={<FilterAltRoundedIcon />}
                    type='submit'
                    disabled={!isValid}
                  >
                    Filtrar
                  </Button>
                  <Button
                    sx={{ width: '20%' }}
                    color='primary'
                    onClick={clearFilters}
                  >
                    <FilterAltOffRoundedIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </form>
          <Loading isLoading={isLoading} error={false}>
            <DataGridStyle
              height={500}
              rows={citas || []}
              columns={columns}
              checkboxSelection={false}
              getRowId={(row) => row.idCita}
            />
          </Loading>
        </ContentMainBox.Content>
      </ContentMainBox>
    </>
  )
}

const columns = [
  {
    headerClassName: 'super-app-theme--header',
    field: 'idCita',
    headerName: 'ID',
    type: 'string',
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'fechaCita',
    headerName: 'Fecha',
    type: 'date',
    valueFormatter: ({ value }) => {
      return value.split('T')[0]
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'hora',
    headerName: 'Hora',
    type: 'string',
    width: 100,
    renderCell: ({ row }) => {
      return `${row.horarioDetalle.horaIni ?? '00'} - ${row.horarioDetalle.horaFin ?? '00'}`
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'especialidad',
    headerName: 'Especialidad',
    width: 200,
    renderCell: ({ row }) => {
      return row.especialista.especialidad.desEspecialidad ?? 'Desconocido'
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    cellClassName: 'uppercase',
    field: 'especialista',
    headerName: 'Especialista',
    width: 300,
    flex: 0.6,
    renderCell: ({ row }) => {
      if (row.especialista.persona.nombres) {
        const { nombres, apPaterno, apMaterno, xd } = row.especialista.persona
        return `${nombres} ${apPaterno} ${apMaterno}`
      }
      return 'Desconocido'
    }
  },
  {
    headerClassName: 'super-app-theme--header',
    field: 'estado',
    headerName: 'Estado',
    width: 200,
    type: 'string',
    renderCell: ({ value }) => {
      return <Chip size="small" color={COLOR_ESTADO[value]} variant="outlined" sx={{ fontWeight: "bold" }} label={ESTADOS[value]} />
    }
  },
]
