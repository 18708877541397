import { yupResolver } from '@hookform/resolvers/yup'
import AccessibilityNewRoundedIcon from '@mui/icons-material/AccessibilityNewRounded'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DeviceThermostatRoundedIcon from '@mui/icons-material/DeviceThermostatRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import HeightRoundedIcon from '@mui/icons-material/HeightRounded'
import MonitorHeartRoundedIcon from '@mui/icons-material/MonitorHeartRounded'
import OpacityRoundedIcon from '@mui/icons-material/OpacityRounded'
import SaveIcon from '@mui/icons-material/Save'
import ScaleRoundedIcon from '@mui/icons-material/ScaleRounded'
import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAlertMessage } from '../../../../context/notifications'

const TriajeSchema = yup.object({
  presionArterial: yup
    .string()
    .required('Campo requerido')
    .typeError('Campo requerido'),
  temperatura: yup
    .number()
    .required('Campo requerido')
    .typeError('Campo requerido'),
  frecuenciaRespiratoria: yup
    .number()
    .required('Campo requerido')
    .typeError('Campo requerido'),
  frecuenciaCardiaca: yup
    .number()
    .required('Campo requerido')
    .typeError('Campo requerido'),
  saturacion: yup
    .number()
    .required('Campo requerido')
    .typeError('Campo requerido'),
  peso: yup
    .number()
    .positive()
    .required('Campo requerido')
    .typeError('Campo requerido'),
  talla: yup
    .number()
    .positive()
    .required('Campo requerido')
    .typeError('Campo requerido'),
})

const formatTriajeData = (disabled) => ({
  presionArterial: {
    nombre: 'Presión arterial',
    valor: '--',
    unidad: 'mmHg',
    Icon: FavoriteRoundedIcon,
    color: '#df4976',
    disabled,
  },
  temperatura: {
    nombre: 'Temperatura',
    valor: '--',
    unidad: '°C',
    Icon: DeviceThermostatRoundedIcon,
    color: '#c68aac',
    disabled,
  },
  saturacion: {
    nombre: 'Saturación',
    valor: '--',
    unidad: '%',
    Icon: OpacityRoundedIcon,
    color: '#b73a61',
    disabled,
  },
  frecuenciaRespiratoria: {
    nombre: 'Frecuencia respiratoria',
    valor: ' --',
    unidad: 'rpm',
    Icon: MonitorHeartRoundedIcon,
    color: '#adadad',
    disabled,
  },
  frecuenciaCardiaca: {
    nombre: 'Frecuencia Cardiaca',
    valor: '--',
    unidad: 'lpm',
    Icon: MonitorHeartRoundedIcon,
    color: '#db5461',
    disabled,
  },
  peso: {
    nombre: 'Peso',
    valor: '--',
    unidad: 'Kg',
    Icon: ScaleRoundedIcon,
    color: '#64a0c2',
    disabled,
  },
  talla: {
    nombre: 'Talla',
    valor: '--',
    unidad: 'cm',
    Icon: HeightRoundedIcon,
    color: '#9fae81',
    disabled,
  },
  imc: {
    nombre: 'IMC',
    valor: '--',
    unidad: 'kg/m²',
    Icon: AccessibilityNewRoundedIcon,
    color: '#d89d48',
    disabled: true,
  },
})

export const FormTriaje = ({ mutate, defaultData, handleClose, open, estadoTriaje, isLoading }) => {
  const { showError, showSuccess } = useAlertMessage()
  const triajeDataDefault = formatTriajeData(estadoTriaje)

  const { reset, control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(TriajeSchema),
    defaultValues: {
      presionArterial: defaultData?.presionArterial ?? '',
      temperatura: defaultData?.temperatura ?? '',
      frecuenciaRespiratoria: defaultData?.frecuenciaRespiratoria ?? '',
      frecuenciaCardiaca: defaultData?.frecuenciaCardiaca ?? '',
      saturacion: defaultData?.saturacion ?? '',
      peso: defaultData?.peso ?? '',
      talla: defaultData?.talla ?? '',
      imc: defaultData?.imc ?? '',
    }
  })

  const [fullWidth] = useState(true)
  const [maxWidth] = useState('md')

  const onSubmit = data => {
    const { idCita, nroDocPaciente } = defaultData

    const formData = {
      idCita,
      nroDocPaciente,
      presionArterial: data.presionArterial,
      temperatura: data.temperatura,
      frecuenciaRespiratoria: data.frecuenciaRespiratoria,
      frecuenciaCardiaca: data.frecuenciaCardiaca,
      saturacion: data.saturacion,
      peso: data.peso,
      talla: data.talla,
      imc: (data.peso) / ((data.talla / 100) ** 2),
      fechaHora: new Date().toISOString(),
    }
    const msg = 'No se pudo guardar datos del triaje'
    const msg2 = 'Datos guardados correctamente'
    mutate(formData, {
      onError: () => {
        showError(msg)
      },
      onSuccess: () => {
        handleClose()
        showSuccess(msg2)
        reset()
      }
    })
  }

  const watchTalla = watch('talla')
  const watchPeso = watch('peso')

  useEffect(() => {
    if (watchPeso && watchTalla) {
      const imc = (watchPeso) / ((watchTalla / 100) ** 2)
      setValue('imc', imc?.toFixed(2))
    }
  }, [watchPeso, watchTalla])

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer'
          }}
        >
          TRIAJE
          <CloseRoundedIcon
            onClick={() => {
              handleClose()
              reset()
            }}
          />
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container spacing={3} alignContent='center'>
              <Grid item xs={12}>
                <Grid container justifyContent="space-around" >
                  <Grid item xs={12} sm={12} p={2}>
                    <List>
                      {
                        Object.keys(triajeDataDefault).map((key, index) => {
                          const { nombre, unidad, Icon, color, disabled } = triajeDataDefault[key]
                          return (
                            <div key={index}>
                              <Divider />
                              <ListItem className='my-[2px]'>
                                <ListItemIcon> <Icon sx={{ color: color }} /> </ListItemIcon>
                                <ListItemText>
                                  <Typography fontWeight="bold">
                                    {nombre}
                                  </Typography>
                                </ListItemText>
                                <Controller
                                  control={control}
                                  name={key}
                                  render={({ field: { onChange, value } }) => (
                                    <TextField
                                      onChange={onChange}
                                      value={value}
                                      sx={{ width: 200 }}
                                      size="small"
                                      disabled={disabled}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="end">{unidad}</InputAdornment>,
                                      }}
                                      helperText={errors[key]?.message}
                                    />
                                  )}
                                />
                              </ListItem>
                            </div>
                          )
                        })
                      }
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='error' startIcon={<CancelRoundedIcon />} onClick={() => handleClose()}>
              Cancelar
            </Button>
            <LoadingButton
              loading={isLoading}
              type='submit'
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'
            >
              GUARDAR
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}