import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useAddFamilyMember } from '../../hooks/back-clinica/usePacientes'
import { FamilyMembersDialog } from './FamilyMembersDialog'

export default function AddFamilyMember({ data }) {
    const { mutate, isLoading } = useAddFamilyMember()

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Button variant='contained' onClick={handleOpen}>
                Agregar
            </Button>
            {
                open &&
                <FamilyMembersDialog
                    mutate={mutate}
                    defaultData={{
                        idPaciente: data.idPaciente,
                    }}
                    isLoading={isLoading}
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                />
            }
        </>
    )
}
