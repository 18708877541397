import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded'
import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AccesosContext } from '../../context/accesosContext'
import { UserContext } from '../../context/userContext'
import { groupAccesos } from '../../utils'
import { Icons} from './icons'

const drawerWidth = 240

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const Sidebar = ({ handleDrawerClose, open, setOpen }) => {
  const { getMenu } = useContext(AccesosContext)
  let accesos = groupAccesos(getMenu().map(item => {
    const { acceso, ...other } = item
    return { ...acceso, ...other }
  }))
  const theme = useTheme()
  const navigate = useNavigate()
  const { user, rol } = useContext(UserContext)
  const person = JSON.parse(localStorage.getItem("persona"))

  const [expanded, setExpanded] = useState('')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleChangeOnClose = (panel) => (event, newExpanded) => {
    setOpen(!open)
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <>
      <Drawer
        variant='permanent'
        open={open}
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <DrawerHeader className='bg-[#217E93]'>
          <IconButton onClick={handleDrawerClose} className='text-[#B5E3E8]'>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Avatar sx={{width:'auto',height:'auto',maxHeight:'40px',maxWidth:'40px'}} alt="foto de perfil" src={user.picture||"https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-High-Quality-Image.png"}/>
          </Box>
          <Box>
            <Typography variant="h5" className='text-[#217E93] text-center mb-4'>{user.rol}</Typography>
          </Box>
          {
            Object.keys(accesos).map((rol, index) => {
              // const Icon = RolIcon[rol] ?? RolIcon['default'];
              return (
                <Grid key={index} >
                  <Box sx={{ display:!open? 'none':'flex', justifyContent: 'center',alignItems: 'center',flexDirection: "column", gap: 1 ,marginBottom:2}}>
                    <Typography color={'title'} sx={{ fontWeight: 'bold', color: '#217E93' }}>
                      {person?.nombres?.toUpperCase()}
                    </Typography>
                    <Typography color={'title'} sx={{ fontSize: "11pt",color: '#217E93' }}>
                      {rol.toUpperCase()}
                    </Typography>
                  </Box>

                  <ul>
                    {accesos[rol].map((element, i) => (
                      element.menu === 1 && (
                        <Link
                          to={`/${rol}/${element.ruta}`}
                          className='no-underline'
                          style={{ color: theme.palette.text.primary }}
                          key={i}
                        >
                          <li>
                            <ListItemButton
                              sx={{
                                maxHeight: 48,
                                justifyContent: 'initial',
                                px: 2.5,
                                display: 'flex',
                              }}
                            >
                              <ListItemIcon
                                className='text-[#217E93]'
                                sx={{
                                  minWidth: 0,
                                  mr: 3,
                                  justifyContent: 'center',
                                }}
                              >
                                <Tooltip title={element?.nombreEtiqueta}>
                                  {Icons[element?.iconoAcceso] ?? Icons['default']}
                                </Tooltip>
                              </ListItemIcon>
                              <ListItemText
                                className='text-[#217E93]'
                                primary={element?.nombreEtiqueta}
                              />
                            </ListItemButton>
                          </li>
                        </Link>
                      )
                    ))}
                  </ul>
                </Grid>
              );
            })
          }
        </List>
        <Divider />
        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Link
            to={'/login'}
            className='no-underline'
            style={{ color: theme.palette.text.primary }}
          >
            <ListItemButton
              sx={{
                maxHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5
              }}
              onClick={() => {
                localStorage.removeItem('token')
                localStorage.removeItem('_p')
                localStorage.removeItem('dni')
                navigate('/login')
              }}
            >
              <ListItemIcon
                className='text-[#217E93]'
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center'
                }}
              >
                <Tooltip title='Cerrar sesión'>
                  <ExitToAppRoundedIcon />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                className='text-[#217E93]'
                primary='Cerrar sesión'
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        </List>
      </Drawer>
    </>
  )
}

export default Sidebar
