import axios from 'axios'

export const axiosClient = axios.create({
})

export const axiosClinicaClient = axios.create({
    baseURL: process.env.REACT_APP_BIENESTAR_CLINICA_API
})

export const axiosPrintService = axios.create({
    baseURL: process.env.REACT_APP_PRINT_SERVICE_URL
})

export function setClient(token) {
    axiosClient.defaults.headers.common.Authorization = `bearer ${token?.slice(1, -1)}`
    axiosClinicaClient.defaults.headers.common.Authorization = `bearer ${token?.slice(1, -1)}`
}
