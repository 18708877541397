import { axiosClinicaClient } from "../../api/axios"

export const getCitasEspecialista = async ({ queryKey }) => {
  const [, ...params] = queryKey
  const [idEspecialista, fechaInicio, fechaFin, dniPaciente, estado] = params

  const queryParams = [
    fechaInicio && `fechaInicio=${fechaInicio}`,
    fechaFin && `fechaFin=${fechaFin}`,
    dniPaciente && `dniPaciente=${dniPaciente}`,
    estado && `estadoCitaMedica=${estado}`
  ].filter(Boolean)

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''

  const { data } = await axiosClinicaClient.get(`/procesos/citas/especialista-atendidas/${idEspecialista}${queryString}`)
  return data
}

export const getCitasPaciente = async ({ queryKey }) => {
  const [, idPaciente, fechaInicio, fechaFin, idEspecialidad] = queryKey
  const queryParams = [
    fechaInicio && `fechaInicio=${fechaInicio}`,
    fechaFin && `fechaFin=${fechaFin}`,
    idEspecialidad && `idEspecialidad=${idEspecialidad}`
  ].filter(Boolean)
  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''

  const { data } = await axiosClinicaClient.get(`/procesos/citas/paciente/${idPaciente}${queryString}`)
  return data
}

export const getHorariosDisponibles = async ({ queryKey }) => {
  const [, fechaCita, idEspecialista] = queryKey
  const fechaCitaQuery = `fechaCita=${fechaCita}`
  const idEspecialistaQuery = `idEspecialista=${idEspecialista}`

  const { data } = await axiosClinicaClient.get(`/procesos/citas/solicitar-cita/listar-horarios?${fechaCitaQuery}&${idEspecialistaQuery}`)
  return data
}

export const createCita = async body => {
  const { data } = await axiosClinicaClient.post(`/procesos/citas/solicitar-cita/agendar`, body)
  return data
}

export const GetHistorialCitasPaciente = async ({ queryKey }) => {
  const [, dniPaciente, fechaInicio, fechaFin] = queryKey
  const {data} = await axiosClinicaClient.get(`/reportes/citas/historial/paciente`, {
    params: { dniPaciente, fechaInicio, fechaFin }
  })
  return data
}

export const GetHistorialCitasEspecialista = async ({ queryKey }) => {
  const [, idEspecialista, fechaInicio, fechaFin] = queryKey
  const {data} = await axiosClinicaClient.get(`/reportes/citas/historial/especialista`, {
    params: { idEspecialista, fechaInicio, fechaFin }
  })
  return data
}
