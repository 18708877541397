import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  Create,
  GetAll,
  GetByEstado,
  GetByIdEspecialista,
  GetByIdPaciente,
  getCitasPacientes,
  Update
} from '../services/citas'

const key = 'citas'

export const useGetCitasByIdPaciente = (idPaciente, enabled = true) =>
  useQuery([key, idPaciente], GetByIdPaciente, {
    enabled
  })

export const useGetCitasByIdEspecialista = (idEspecialista, enabled = true) =>
  useQuery([key, idEspecialista], GetByIdEspecialista, {
    enabled
  })

export const useUpdateCita = idCita => {
  const queryClient = useQueryClient()
  return useMutation(body => Update(idCita, body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useCreateCita = () => {
  const queryClient = useQueryClient()
  return useMutation(body => Create(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useGetAllCitas = (enabled = true) =>
  useQuery(key, GetAll, {
    enabled
  })

export const useGetCitas = (idEspecialista, estado, enabled = true) =>
  useQuery([key, idEspecialista, estado], GetByEstado, {
    enabled
  })

export const useGetCitasPacientes = (idPaciente, enabled = true) =>
  useQuery([key, idPaciente], getCitasPacientes, {
    enabled
  })
