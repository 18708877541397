import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createTriaje, getTriajes } from '../../services/back-clinica/triaje'

const key = 'triajes'

export const useCreateTriaje = () => {
  const queryClient = useQueryClient()

  return useMutation(createTriaje, {
    onSuccess: () => {
      queryClient.invalidateQueries(key);
    }
  })
}

export const useGetTriajes = (searchParams, enabled = true) =>
  useQuery({
    queryKey: [key, JSON.stringify(searchParams)],
    queryFn: () => getTriajes(searchParams),
    select: (data) => {
      return data.map(({ especialista: especialistaDetalle, horarioDetalle, paciente: pacienteDetalle, triaje, ...others }) => ({
        ...others,
        paciente: `${pacienteDetalle?.persona?.nombres} ${pacienteDetalle?.persona?.apPaterno} ${pacienteDetalle?.persona?.apMaterno}`,
        nroDocPaciente: pacienteDetalle?.persona?.dni,
        especialista: `${especialistaDetalle?.persona?.nombres} ${especialistaDetalle?.persona?.apPaterno} ${especialistaDetalle?.persona?.apMaterno}`,
        especialidad: especialistaDetalle?.especialidad?.desEspecialidad,
        hora: `${horarioDetalle?.horaIni ?? ''} - ${horarioDetalle?.horaFin ?? ''}`,
        especialistaDetalle,
        pacienteDetalle,
        triaje: triaje.length > 0 ? triaje[0] : null,
        estadoTriaje: Number(triaje.length > 0),
      }))
    },
    enabled,
  })