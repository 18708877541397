import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createCita, getCitasEspecialista, getCitasPaciente, GetHistorialCitasEspecialista, GetHistorialCitasPaciente, getHorariosDisponibles } from '../../services/back-clinica/citas'

const key = 'citas'

export const useGetCitasEspecialista = ({
  idEspecialista,
  fechaInicio = '',
  fechaFin = '',
  dniPaciente = '',
  estado = ''
}, enabled = true) =>
  useQuery([key, idEspecialista, fechaInicio, fechaFin, dniPaciente, estado], getCitasEspecialista, {
    enabled
  })

export const useGetCitasPaciente = ({
  idPaciente,
  fechaInicio,
  fechaFin,
  idEspecialidad,
}, enabled = true) =>
  useQuery([key, idPaciente, fechaInicio, fechaFin, idEspecialidad], getCitasPaciente, {
    enabled
  })


export const useGetHorariosDisponibles = ({
  fechaCita,
  idEspecialista
}, enabled = true) =>
  useQuery([key, fechaCita, idEspecialista], getHorariosDisponibles, {
    enabled
  })

export const useCreateCita = () => {
  const queryClient = useQueryClient()
  return useMutation(body => createCita(body), {
    onSuccess: () => queryClient.invalidateQueries(key)
  })
}

export const useGetHistorialCitasEspecialista = (idEspecialista, fechaInicial, fechaFinal, enabled = true) =>
  useQuery([key, idEspecialista, fechaInicial, fechaFinal], GetHistorialCitasEspecialista, {enabled})

export const useGetHistorialCitasPaciente = (dniPaciente, fechaInicial, fechaFinal, enabled = true) =>
  useQuery([key, dniPaciente, fechaInicial, fechaFinal], GetHistorialCitasPaciente, {enabled})