import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import { Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import { UserContext } from '../../../../context/userContext'
import { useCreateTriaje } from '../../../../hooks/back-clinica/useTriajeClinica'
import { DialogTriaje } from '../../NuevasConsultas/components/DialogTriaje'
import { FormTriaje } from './FormTriaje'


export default function Triaje({ data }) {
    const { mutate, isLoading } = useCreateTriaje()
    const { especialidad } = useContext(UserContext)

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    if (!especialidad?.requiereTriaje) {
        return <Button disabled>
            Triaje
        </Button>
    }

    const haveTriaje = !!data.triaje

    if (haveTriaje) {
        return (
            <>
                <Button variant={haveTriaje ? 'outlined' : 'contained'} size='small' onClick={handleOpen}
                    startIcon={<MedicalInformationIcon />}
                >
                    Triaje
                </Button>
                {
                    open &&
                    <DialogTriaje
                        mutate={mutate}
                        defaultData={{
                            ...data.triaje,
                            idCita: data.idCita,
                            nroDocPaciente: data.nroDocPaciente,
                        }}
                        estadoTriaje={Boolean(data.estadoTriaje)}
                        isLoading={isLoading}
                        open={open}
                        handleOpen={handleOpen}
                        handleClose={handleClose}
                    />
                }
            </>
        )
    }

    return (
        <>
            <Button variant={haveTriaje ? 'outlined' : 'contained'} size='small' onClick={handleOpen}
                startIcon={<MedicalInformationIcon />}
            >
                Triaje
            </Button>
            {
                open &&
                <FormTriaje
                    mutate={mutate}
                    defaultData={{
                        ...data.triaje,
                        idCita: data.idCita,
                        nroDocPaciente: data.nroDocPaciente,
                    }}
                    estadoTriaje={Boolean(data.estadoTriaje)}
                    isLoading={isLoading}
                    open={open}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                />
            }
        </>
    )
}
