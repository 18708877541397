import { yupResolver } from '@hookform/resolvers/yup';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import HttpsRoundedIcon from '@mui/icons-material/HttpsRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import { Container } from '@mui/system';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Input from '../../components/Input';
import { CitaContext } from '../../context/citasContext';
import { useAlertMessage } from '../../context/notifications';
import { UserContext } from '../../context/userContext';
import { LoginGoogleService, LoginService } from '../../services/login';
import Fondo from './Components/assets/images/Fondo.jpg';
const LoginSchema = yup.object({
  email: yup
    .string()
    .required('* Campo requerido'),
  contrasena: yup
    .string()
    .required('* Campo requerido'),
})

const styles = {
  root: {
    backgroundImage: `url(${Fondo})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100vh',
    position: 'relative',
    minHeight: '100vh'

  },
  overOpacity: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: '#999999',
    opacity: 0.5,
  },
  content: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }

};

const Login = () => {
  const { setUser, setRol, setEspecialidad, rol } = useContext(UserContext)
  const navigate = useNavigate()
  const { showError, showSuccess } = useAlertMessage()

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(LoginSchema),
    defaultValues: { email: '', contrasena: '' }
  })

  const [loggeIn, setLoggetInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const contexto_cita = useContext(CitaContext)
  const onSubmit =  (data) => {
    
    setIsLoading(true)
    LoginService(data).then(response => {
      const user = response.data.listaObjetos[1]
      const especialista = response.data.listaObjetos[3]
      const persona = user?.persona

      localStorage.setItem("persona", JSON.stringify(persona))
      setUser({
        email: user.email,
        nombres: persona?.nombres,
        dni: user.dni,
        perfil: {
          perfilCodigo: user.perfil.perfilCodigo,
          perfilNombre: user.perfil.perfilNombre,
          descripcion: user.perfil.descripcion,
          icono: user.perfil.icono,
          estado: user.perfil.estado,
        },
        estado: user.estado,
      })

      setRol(user.perfil.perfilNombre)
      
      especialista.length > 0 && setEspecialidad({
        idEspecialidad: especialista[0].especialidad.idEspecialidad,
        desEspecialidad: especialista[0].especialidad.desEspecialidad,
        idEspecialista: especialista[0].idEspecialista,
        requiereTriaje: especialista[0].especialidad.requiereTriaje,
      })

      localStorage.setItem('token', JSON.stringify(response.data.listaObjetos[0].token))
      localStorage.setItem('dni', JSON.stringify(response.data.listaObjetos[1].dni))
      localStorage.setItem("_p", JSON.stringify(response.data.listaObjetos[2]))
      especialista.length > 0 && localStorage.setItem("especialidad", JSON.stringify(especialista[0]))
      navigate(`/${user.perfil.perfilNombre}/inicio`)

      if (contexto_cita.data.idPaciente !== null) {
        navigate('/paciente/solicitarcita')
      }
      setIsLoading(false)
      showSuccess('Inicio de sesión correcto')
    }).catch(err => {
      console.log(err)
      showError(err.response?.data?.mensaje)
      setIsLoading(false)
    })
    
  }
  // codigo de Google Auth
  const onSuccess = (credentialResponse) => {
    setIsLoading(true)
    const decodedToken = jwtDecode(credentialResponse.credential, { complete: true });
    localStorage.setItem('picture',decodedToken.picture)
    LoginGoogleService(credentialResponse.credential).then(response => {
  
      const user = response.data.listaObjetos[1]
      setUser({
        email: user.email,
        nombres: decodedToken.name,
        picture:decodedToken.picture,
        dni: user.dni,
        perfil: {
          perfilCodigo: user.perfil.perfilCodigo,
          perfilNombre: user.perfil.perfilNombre,
          descripcion: user.perfil.descripcion,
          icono: user.perfil.icono,
          estado: user.perfil.estado,
        },
        estado: user.estado,
      })
      setRol(user.perfil.perfilNombre)
      const especialista = response.data.listaObjetos[3]
      especialista.length > 0 && setEspecialidad({
        idEspecialidad: especialista[0].especialidad.idEspecialidad,
        desEspecialidad: especialista[0].especialidad.desEspecialidad,
        idEspecialista: especialista[0].idEspecialista,
        requiereTriaje: especialista[0].especialidad.requiereTriaje,
      })
      localStorage.setItem('token', JSON.stringify(response.data.listaObjetos[0].token))
      localStorage.setItem('dni', JSON.stringify(response.data.listaObjetos[1].dni))
      localStorage.setItem("_p", JSON.stringify(response.data.listaObjetos[2]))
      especialista.length > 0 && localStorage.setItem("especialidad", JSON.stringify(especialista[0]))
      navigate(`/${user.perfil.perfilNombre}/inicio`)

      if (contexto_cita.data.idPaciente !== null) {
        navigate('/paciente/solicitarcita')
      }

      showSuccess('Inicio de sesión correcto')
    }).catch(err => {
      console.log(err)
      showError(err.response.data.mensaje)
    })
    setIsLoading(false)


    // console.log(credentialResponse.credential);
    // var decoded = jwtDecode(credentialResponse.credential);
    // console.log(decoded)
    // localStorage.setItem('token', credentialResponse.credential)
    // localStorage.setItem('name', decoded.name)
    // localStorage.setItem('picture', decoded.picture)
    // setTimeout(() => {
    // }, 1000);
    // navigate(`/${rol}`)
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={{ width: '100%', height: '100vh' }}>
      <div style={styles.root}></div>
      <div style={styles.overOpacity}></div>
      {/* <img src={Fondo}></img>   */}
      <Box style={styles.content}  >
        <Avatar alt="Remy Sharp" sx={{
          m: 2, width: 150, height: 150, bgcolor: '#217E93',
          p: 2,
          position: 'relative',
          // top:10,
          zIndex: 'tooltip',
        }}>
          <AccountCircleRoundedIcon sx={{ width: 150, height: 150 }} />
        </Avatar>
        <Box sx={{
          display: 'flex', flexWrap: 'wrap',
          position: 'relative',
          bottom: 75,
        }}>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              '& > :not(style)': {
                width: { sm: 400, md: 400, xs: 360 },
                height: 480,

              },
            }}
          >
            <Paper elevation={3} sx={{ borderRadius: 2, background: 'rgba(225,225,225,0.6)' }}>
              <Container component="main" maxWidth="xs"   >
                <Box
                  sx={{ marginTop: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Box sx={{ mt: 2, mx: 4 }}>
                    {contexto_cita.data.idPaciente !== null ?
                      <Box sx={{ textAlign: 'center' }}>
                        <h1 style={{ color: 'black', fontSize: 20, fontWeight: 700 }}>
                          ¡Bienvenido al sistema de Bienestar!
                        </h1>
                        <h1 style={{ color: 'red', fontSize: 18, fontWeight: 700 }}>
                          ¡Antes de crear una cita INICIE SESION!
                        </h1>
                      </Box>
                      :
                      <Box sx={{ textAlign: 'center' }}>
                        <h1 style={{ color: 'black', fontSize: 20, fontWeight: 700 }}>
                          ¡Bienvenido al sistema de Bienestar!
                        </h1>
                        
                      </Box>
                    }
                    {
                      (loggeIn ?
                        <Box sx={{ alignItems: 'center',border:'1px solid blue' }} >
                          <img width={50} height={50} src={userLogin.imageUrl} />
                          <h3>{userLogin.name}</h3>
                        </Box>
                        :
                        <Box sx={{ flexDirection:'column',justifyContent: 'center', alignItems: 'center', padding: 2 ,gap:2,display:'flex'}} >
                          <Box sx={{ textAlign: 'center' }}>
                            <h2 style={{ color: 'black', fontSize: 14, fontWeight: 400 }}>
                            Ingresa al sistema mediante tu cuenta de correo UNIQ o tu correo asignado 
                            </h2>
                          </Box>
                          <Box sx={{ textAlign: 'center' }}>
                          <GoogleLogin
                            size='large'
                            onSuccess={onSuccess}
                            onError={() => {
                              console.log('Login Failed');
                            }} />
                            </Box>
                        </Box>
                      )
                    }
                    <Divider orientation="horizontal" flexItem sx={{ color: 'black', marginBottom: '20px' }}>
                      o continúa con tu cuenta
                    </Divider>
                    
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                      >
                        <Grid item xs={12}>
                          <Input
                            control={control}
                            id='email'
                            label='email'
                            placeholder='Ingrese su email'
                            type='text'
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <PersonRoundedIcon sx={{ color: 'primary.main' }} />
                                </InputAdornment>
                              )
                            }}
                          />
                          <Typography
                            variant='subtitle2'
                            align='left'
                            color='primary'
                          >
                            {errors.email?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Input
                            control={control}
                            id='contrasena'
                            label='Contraseña'
                            placeholder='Ingrese su contraseña'
                            type='password'
                            inputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <HttpsRoundedIcon sx={{ color: 'primary.main' }} />
                                </InputAdornment>
                              )
                            }}
                          />
                          <Typography
                            variant='subtitle2'
                            align='left'
                            color='primary'
                          >
                            {errors.contrasena?.message}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <LoadingButton
                            loading={isLoading}
                            type='submit'
                            variant='contained'
                            fullWidth
                            sx={{
                              '&.MuiButton-contained': {
                                backgroundColor: isLoading ? 'primary.main' : undefined
                              },
                            }}
                            endIcon={<SendIcon />}
                          >
                            Ingresar
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    {/* <Grid container>
                      <Grid item xs sx={{ zIndex: 'tooltip', mb: 5, textAlign: 'center', }}>
                        <Link href="#" variant="body2" >
                          ¿Olvidaste tu contraseña?
                        </Link>
                      </Grid>
                    </Grid> */}

                  </Box>
                </Box>

              </Container>
            </Paper>
          </Box>



        </Box>
      </Box>
    </div>
    </form>
  );
}
export default Login
